import { Component, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('menuBtn', { read: ElementRef }) menuBtn: ElementRef;

  isExpanded = false;
  isHandheld: Observable<BreakpointState> = this.breakPointObserver.observe(Breakpoints.Handset);

  constructor(private breakPointObserver: BreakpointObserver) {
    
  }

  toggle() {
    this.sidenav.toggle();
  }

  close() {
    this.menuBtn.nativeElement.blur();
    this.sidenav.close();
  }
}
