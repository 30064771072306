import { Component } from '@angular/core';

@Component({
  selector: 'app-saatieto-component',
  templateUrl: './saatieto.component.html'
})
export class SaatietoComponent {
  currentDateTime: Date = new Date();

  constructor() {
  }

  ngOnInit() {
    console.log('SaatietoComponent')
  }
}
