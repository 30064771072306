import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiMittausKokoelma, ApiMittausArvo } from '../models/kojelauta.model';
import { DatePipe, DecimalPipe } from '@angular/common'

@Injectable({
  providedIn: 'root'
})

export class KojelautaService {
  constructor(private http: HttpClient,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    @Inject('BASE_URL') private baseUrl: string) { }

  public transformArvo(arvo: ApiMittausArvo): any {
    if (arvo == null)
      return null;
    switch (arvo.tyyppi) {
      case 'DateTime':
        return this.datePipe.transform(arvo.arvoData, 'dd.MM.yyyy HH:mm:ss', '+0000');
      case 'Int16':
      case 'Int32':
        return +arvo.arvoData;
      case 'Decimal':
        return this.decimalPipe.transform(arvo.arvoData?.replace(',', '.'), '1.1-2', 'fi-FI')
      default:
        return arvo.arvoData;
    };
  }


  public haeModulinViimeisetTunnit(moduliId: number, tunnit: number = null) {
    tunnit = tunnit ?? 12;
    let params = new HttpParams().set('tuntiMaara', tunnit.toString());
    return this.http.get<ApiMittausKokoelma[]>(this.baseUrl + 'api/kojelauta/moduli/' + moduliId + '/tunti', { params: params });
  }

  public haeAsemanViimeisetTunnit(asemaId: number, tunnit: number = null) {
    tunnit = tunnit ?? 12;
    let params = new HttpParams().set('tuntiMaara', tunnit.toString());
    return this.http.get<ApiMittausKokoelma[]>(this.baseUrl + 'api/kojelauta/asema/' + asemaId + '/tunti', { params: params });
  }
}
