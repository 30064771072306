import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { EtusivuComponent } from './components/etusivu/etusivu.component';
import { CounterComponent } from './components/counter/counter.component';
import { FetchDataComponent } from './components/fetch-data/fetch-data.component';
import { SaaValintaComponent } from './components/saa-valinta/saa-valinta.component';
import { SaatietoComponent } from './components/saatieto/saatieto.component';
import { SaatietoPaivaComponent } from './components/saatieto-paiva/saatieto-paiva.component';
import { KaavioComponent } from './components/kaavio/kaavio.component';
import { LampotilakaavioComponent } from './components/kaavio/lampotilakaavio.component';
import { SademaarakaavioComponent } from './components/kaavio/sademaarakaavio.component';

import { YhteenvetoComponent } from './components/yhteenveto/yhteenveto.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';

import localeFi from '@angular/common/locales/fi';

registerLocaleData(localeFi, 'fi');

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,

    EtusivuComponent,
    SaaValintaComponent,
    SaatietoComponent,
    SaatietoPaivaComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    YhteenvetoComponent,
    LampotilakaavioComponent,
    SademaarakaavioComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    //Angular Material modules:
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTableModule,
    MatCardModule,
    MatTabsModule,
    MatDatepickerModule,
    //MatNativeDateModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    //Charts:
    NgxChartsModule
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    MatDatepickerModule,
    //MatNativeDateModule,
    { provide: MAT_DATE_LOCALE, useValue: 'fi-FI' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
