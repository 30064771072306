import { Component, ViewChild } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { IKaavio } from '../../../models/kaavio.model';
import { KaavioService } from '../../services/kaavio.service';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap, tap, finalize, map } from 'rxjs/operators';
import { DatehelperService } from '../../services/datehelper.service';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-saatieto-paiva-component',
  templateUrl: './saatieto-paiva.component.html'
})
export class SaatietoPaivaComponent {
  @ViewChild(MatDatepicker) picker: MatDatepicker<Date>;

  paivaTanaan: Date;
  valittuPaivaControl = new FormControl(new Date());
  testi: '{{1+1}}'

  lampotilaKaaviot$: Observable<IKaavio[]>;
  sademaaraKaaviot$: Observable<IKaavio[]>;
  tuuliKaaviot$: Observable<IKaavio[]>;

  lampotilaHakuKesken: false;
  sademaaraHakuKesken: false;

  lampotilaScheme = {
    domain: ['#eb4034']
  };

  sademaaraScheme = {
    domain: ['#344ceb']
  };

  constructor(private kaavioService: KaavioService,
    private datehelperService: DatehelperService) {
    this.paivaTanaan = new Date();
    this.valittuPaivaControl.setValue(new Date());
  }

  ngOnInit() {
    this.haeKaaviot();
    this.valittuPaivaControl.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(paiva => {
          if (paiva != null) {
            this.haeKaaviot(paiva);
            return of(null);
          }
          return of(null);
        })
      ).subscribe();
  };

  lampotilaTooltip = function () {
    return "{{model.value | number : '1.1 - 2':'fi - FI'}} °C @ {{model.name | date: 'HH: mm':' + 0000'}}";
  };

  haeKaaviot = function (paiva: Date = null) {
    this.lampotilaHakuKesken = true;
    this.sademaaraHakuKesken = true;

    this.lampotilaKaaviot$ = this.kaavioService.haePaivanLampotilat(paiva)
      .pipe(
        tap((r) => console.log(r)),
        finalize(() => this.lampotilaHakuKesken = false)
      );
    this.sademaaraKaaviot$ = this.kaavioService.haePaivanSademaarat(paiva)
      .pipe(
        tap((r) => console.log(r)),
        finalize(() => this.sademaaraHakuKesken = false)
      );
  };

  vaihdaPaiva = function (paivalisays: number) {
    let uusiPaiva = new Date(this.valittuPaivaControl.value || new Date());
    uusiPaiva.setDate(uusiPaiva.getDate() + paivalisays)
    if (uusiPaiva <= this.paivaTanaan) {
      this.valittuPaivaControl.setValue(uusiPaiva);
    }
  };

  disabloiTulevaisuudenPaivat = function (p: Date) {
    return (p || new Date()) <= new Date();
  };
}
