import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DatehelperService {
  constructor() { }

  public dateIsBefore(firstDate: Date, secondDate: Date): boolean {
    firstDate = firstDate || new Date();
    secondDate = secondDate || new Date();
    
    return +(firstDate.getFullYear() + ('0' + (firstDate.getMonth() + 1)).slice(-2) + ('0' + firstDate.getDate()).slice(-2))
      <= +(secondDate.getFullYear() + ('0' + (secondDate.getMonth() + 1)).slice(-2) + ('0' + secondDate.getDate()).slice(-2));

    if (firstDate.getFullYear() < secondDate.getFullYear())
      return true;
    if (firstDate.getMonth() < secondDate.getMonth())
      return true;
    if (firstDate.getDate() <= secondDate.getDate() && firstDate.getMonth() == secondDate.getMonth())
      return true;
    else
      return false;
  } 
}
