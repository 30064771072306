import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { ApiAsema } from '../models/asema.model';
import { Observable } from 'rxjs';
import { ApiModuli } from '../models/moduli.model';

@Injectable({
  providedIn: 'root'
})

export class AsemaService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  public hae(id: number) {
    return this.http.get<ApiAsema>(this.baseUrl + 'api/asema/' + id);
  }

  public haeKaikkiAktiiviset(): Observable<ApiModuli[]> {
    return this.http.get<ApiModuli[]>(this.baseUrl + 'api/asema');
  }
}
