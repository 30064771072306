import { Component, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { KaavioService } from '../../services/kaavio.service';
import { IKaavio } from '../../../models/kaavio.model';
import { ISarja } from '../../../models/sarja.model';
import { IKuvaaja } from '../../../models/kuvaaja.model';
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-etusivu-component',
  templateUrl: './etusivu.component.html'
})
export class EtusivuComponent {
  $lampotilaKaaviot: Observable<IKaavio[]>;

  public xAxisTicks: string[] = [];
  public aikaIkkunat: number[];

  hourTickArr: number[];
  timeline: boolean = true;
  constructor(private kaavioService: KaavioService,
    private datePipe: DatePipe) {
    console.log('xAxisTicks', this.xAxisTicks);
  }

  xAxisTickFormatter(val: string) {
    return val.slice(11,16); 
  };

  ngOnInit() {
    console.log('xAxisTicks', this.xAxisTicks);
    this.$lampotilaKaaviot = this.kaavioService.haePaivanLampotilat()
      .pipe(

    );
  }
}
