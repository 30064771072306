import { Component, Input, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { IKaavio } from '../../../models/kaavio.model';
import { DatePipe, DecimalPipe } from '@angular/common';
import { isArray } from 'util';

@Component({
  selector: 'app-kaavio-component'
})
export abstract class KaavioComponent {
  @Input() kaaviot$: Observable<IKaavio[]>;
  @Input('latausKesken') latausKesken: boolean;

  protected kaavioNimi: string;
  protected xAxisFormat: string;
  protected xAxisLabel: string = 'Aika';
  protected yAxisLabel: string;
  protected yScaleMax: number = null;
  protected yScaleMin: number = null;
  protected chartScheme = {
    domain: ['#344ceb']
  };

  constructor() {
  }

  ngOnInit() {
  };

  abstract xAxisTickFormatter(val: any);
  abstract renderTooltipTemplate(model: any);
}
