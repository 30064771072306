import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http';
import { Time } from '@angular/common';
import { IKaavio } from '../../models/kaavio.model';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class KaavioService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  public haeAikaIkkunat(): number[] {
    return [0, 3, 6, 9, 12, 15, 18, 21 ];
  };


  public haePaivanLampotilat(pvm: Date = null): Observable<IKaavio[]> {
    let pvmString: string = pvm != null ? pvm.toISOString().slice(0, 10) : null;
    let params = null;
    if (pvmString != null) {
      params = new HttpParams().set('pvm', pvmString.toString());
    }
      
    return this.http.get<IKaavio[]>(this.baseUrl + 'api/kaavio/lampotila/paiva', { params: params });
  }

  public haePaivanSademaarat(pvm: Date = null): Observable<IKaavio[]> {
    let pvmString: string = pvm != null ? moment(pvm).format('YYYY-MM-DD') : null;

    let params = null;
    if (pvmString != null) {
      params = new HttpParams().set('pvm', pvmString);
    }
      
    return this.http.get<IKaavio[]>(this.baseUrl + 'api/kaavio/sade/paiva', { params: params });
  }
}
