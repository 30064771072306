import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { ApiModuli } from '../models/moduli.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ModuliService {
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  public hae(id: number) {
    return this.http.get<ApiModuli>(this.baseUrl + 'api/moduli/' + id);
  }

  public haeKaikkiAktiiviset(): Observable<ApiModuli[]> {
    return this.http.get<ApiModuli[]>(this.baseUrl + 'api/moduli');
  }
}
