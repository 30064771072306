import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YhteenvetoComponent } from './components/yhteenveto/yhteenveto.component';
import { EtusivuComponent } from './components/etusivu/etusivu.component';
import { SaaValintaComponent } from './components/saa-valinta/saa-valinta.component';

const routes: Routes = [
  { path: '', component: EtusivuComponent, pathMatch: 'full' },
  { path: 'etusivu', component: EtusivuComponent },
  { path: 'saa', component: SaaValintaComponent },
  { path: 'yhteenveto', component: YhteenvetoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
