import { Component, Input } from '@angular/core';
import { KaavioComponent } from './kaavio.component';
import { DecimalPipe, DatePipe } from '@angular/common';
import { isArray } from 'util';

@Component({
  selector: 'app-lampotilakaavio-component',
  templateUrl: './lampotilakaavio.component.html'
})
export class LampotilakaavioComponent extends KaavioComponent {
  constructor(protected datePipe: DatePipe,
              protected decimalPipe: DecimalPipe) {
    super();
  }

  ngOnInit() {
    super.kaavioNimi = 'Lämpötila';
    super.yAxisLabel = 'Lämpötila (°C)';
    super.chartScheme = {
      domain: ['#eb4034']
    };
  };

  xAxisTickFormatter(val: string) {
    return val.slice(11, 16);
  };

  renderTooltipTemplate(model: any) {
    if (isArray(model)) {
      [model] = model;
    }
    return this.decimalPipe.transform(model.value, '1.1-2', 'fi-FI') + ' °C @ ' + this.datePipe.transform(model.name, 'HH:mm', '+0000');
  }
}
