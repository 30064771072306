import { Component } from '@angular/core';

@Component({
  selector: 'app-saa-valinta-component',
  templateUrl: './saa-valinta.component.html'
})
export class SaaValintaComponent {
  constructor() {
  }

  ngOnInit() {
  
  }
}
