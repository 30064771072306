import { Component } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { KaavioComponent } from './kaavio.component';
import { isArray } from 'util';

@Component({
  selector: 'app-sademaarakaavio-component',
  templateUrl: './sademaarakaavio.component.html'
})
export class SademaarakaavioComponent extends KaavioComponent {
  constructor(protected datePipe: DatePipe,
              protected decimalPipe: DecimalPipe) {
    super();
  }

  ngOnInit() {
    super.kaavioNimi = 'Sademäärä';
    super.yAxisLabel = 'Sademäärä (mm)';
    super.yScaleMin = 0;
    super.chartScheme = {
      domain: ['#344ceb']
    };
  };

  xAxisTickFormatter(val: string) {
    return val.slice(11, 16);
  };

  renderTooltipTemplate(model: any) {
    if (isArray(model)) {
      [model] = model;
    }
    return this.decimalPipe.transform(model.value, '1.1-2', 'fi-FI') + ' mm @ ' + this.datePipe.transform(model.name, 'HH:mm', '+0000');
  }
}
