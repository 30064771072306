import { Component, ViewChild } from '@angular/core';
import { switchMap, tap, debounceTime, flatMap, map, mergeMap, concatMap, toArray, mergeAll, reduce } from 'rxjs/operators';
import { ModuliService } from '../../services/moduli.service';
import { ApiModuli } from '../../models/moduli.model';
import { Observable, of, forkJoin, from, zip, concat } from 'rxjs';
import { KojelautaService } from '../../services/kojelauta.service';
import { ApiMittausArvo } from '../../models/kojelauta.model';
import { MatSort } from '@angular/material/sort';
import { AsemaService } from '../../services/asema.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-yhteenveto-component',
  templateUrl: './yhteenveto.component.html'
})
export class YhteenvetoComponent {
  asemat$;
  modulit$;
  modulitIsBusy: boolean = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private moduliService: ModuliService,
    private asemaService: AsemaService,
    private kojelautaService: KojelautaService) {

  }

  ngOnInit() {
    this.modulit$ = forkJoin(
      this.moduliService.haeKaikkiAktiiviset().pipe(
        mergeMap(modulit => forkJoin(modulit.map(m => {
          return this.kojelautaService.haeModulinViimeisetTunnit(m.id, 12)
            .pipe(
              switchMap(k => {
                m.mittausKokoelmat = k;
                return of(m);
              })
            )
        })))
      ),
      this.asemaService.haeKaikkiAktiiviset().pipe(
        mergeMap(asemat => forkJoin(asemat.map(a => {
          return this.kojelautaService.haeAsemanViimeisetTunnit(a.id, 12)
            .pipe(
              switchMap(k => {
                a.mittausKokoelmat = k;
                return of(a);
              })
            )
        })))
      ))
      .pipe(
        tap(data => console.log(data)),
        map(data => {
          return [].concat.apply([].concat.apply([], data[1]), data[0]);
        })
      );
  }

  public transform(arvo: ApiMittausArvo): any {
    return this.kojelautaService.transformArvo(arvo);
  }
}
